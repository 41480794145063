@import '~@fontsource/roboto/100.css';
@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';
@import '~@fontsource/roboto/900.css';

button.asLink {
  text-align: left;
  padding: 0;
  min-height: 0;
  min-width: 0;
}

button.asLink .span {
  font-size: inherit;
}
